import GATSBY_COMPILED_MDX from "/opt/render/project/src/src/posts/raleigh-office.md";
import {graphql} from "gatsby";
import {GatsbyImage} from "gatsby-plugin-image";
import React from "react";
import Layout from "./layout";
import Seo from "./seo";
import Slider from "./slider";
function PostSingle({data, children}) {
  const {frontmatter, artist} = data.mdx;
  return React.createElement(Layout, null, React.createElement(Seo, {
    title: frontmatter.title
  }), React.createElement("div", {
    className: "mt-10 prose-lg"
  }, React.createElement("h1", {
    className: "inline text-2xl font-semibold uppercase"
  }, frontmatter.title), frontmatter.eventDate && React.createElement("span", {
    className: "pl-4 text-2xl font-thin"
  }, frontmatter.eventDate), React.createElement("div", {
    className: "grid grid-cols-1 gap-10 lg:grid-cols-2"
  }, frontmatter.slider && React.createElement("div", {
    className: "mt-[1.33em]"
  }, React.createElement(Slider, {
    images: frontmatter.slider
  })), React.createElement("div", {
    className: ""
  }, children)), artist && React.createElement("div", {
    className: "grid grid-cols-1 md:gap-10 md:grid-cols-3"
  }, artist.photo && React.createElement("div", {
    className: "mt-[1.33em] max-w-md"
  }, React.createElement(GatsbyImage, {
    image: artist.photo.childImageSharp.gatsbyImageData,
    alt: artist.name
  })), React.createElement("div", {
    className: "md:col-span-2"
  }, React.createElement("h2", {
    className: "text-2xl font-thin"
  }, React.createElement("span", {
    className: "pr-4 font-bold uppercase"
  }, " About the Artist"), " ", artist.name), React.createElement("p", null, artist.description)))));
}
export default function GatsbyMDXWrapper(props) {
  return React.createElement(PostSingle, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
export const query = graphql`
  query PostsByID($id: String!) {
    mdx(id: { eq: $id }) {
      ...PostData
    }
  }
`;
