/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    strong: "strong"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.strong, null, "In the summer of 2022, Robins & Morton opened its tenth office in Raleigh-Durham, North Carolina. Robins & Morton has worked in Raleigh-Durham for more than 15 years and has completed major healthcare projects for clients such as Duke Health. The company has worked in the state of North Carolina since 1980.")), "\n", React.createElement(_components.p, null, "“I’ve worked solely in this community for the last decade, and it’s been amazing to see the growth firsthand,” Senior Project Manager and Raleigh-Durham Office Leader Mike Dare said. “We’re thrilled to make our commitment to Raleigh-Durham official with a permanent location.”"), "\n", React.createElement(_components.p, null, "The Raleigh-Durham team marked the occasion with a grand opening celebration on September 28 at the office located at 5001 South Miami Boulevard, Suite 101, in Durham."), "\n", React.createElement(_components.p, null, "This is Robins & Morton’s second office in North Carolina, preceded by its Charlotte location, established in 2007."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
